import React from 'react'
import { Link } from 'gatsby'

const PostList = ({ posts }) => {
  return (
    <div className='container container m-auto flex flex-wrap flex-col md:flex-row items-center justify-start'>

      <div className='w-full p-3'>
        {posts
          .filter(post => post.node.frontmatter.templateKey === 'article-page')
          .map(({ node: post }) => (
            <div
              className='flex flex-col lg:flex-row rounded overflow-hidden h-auto border shadow shadow-lg'
              style={{ border: '1px solid #eaecee', padding: '2em 4em' }}
              key={post.id}
            >
              <div className='bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal'>
                <Link className='text-black font-bold text-3xl font-primary mb-2 leading-tight' to={post.fields.slug}>{post.frontmatter.title}</Link>
                <div className='text-black font-normal text-xl mb-2 leading-tight'>
                  {post.excerpt}</div>
                <Link to={post.fields.slug} className='text-gray-700 text-base'>Keep Reading →</Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default PostList
