import React, { Component } from 'react'
import { Link } from 'gatsby'
import config from '../../config'
import Helmet from 'react-helmet'
import PostList from '../components/PostList'
import Page from '../components/Page'

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/blog/${props.url}`} className='button is-rounded'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='button is-rounded'>
        {props.text}
      </span>
    )
  }
}

export default class BlogPage extends Component {
  render () {
    const { pageContext } = this.props
    const { group, index, first, last } = pageContext
    const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()
    const nextUrl = (index + 1).toString() + '/'

    const websiteSchemaOrgJSONLD = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    }

    return (
      <Page>
        <Helmet>
          <title>Blog | Godfathers of Sex Abuse</title>
          {/* Schema.org tags */}
          <script type='application/ld+json'>
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>
        </Helmet>
        <section className='font-sans hero-blog'>
          <div className='container m-auto flex flex-col md:flex-row max-w-6xl'>
            <div className='flex flex-col w-full lg:w-1/2 justify-center items-start text-white py-8'>
              <span className='my-4 text-4xl font-thin font-primary uppercase'>
              The Blog
              </span>
            </div>
            <div className='w-full lg:w-1/4 lg:py-6' />
            <div className='w-full lg:w-1/4 lg:py-6'>
              <img
                className='w-full'
                alt='Book Cover'
                src='/img/book.jpg'
              />
            </div>
          </div>
        </section>
        <section className='section'>
          <PostList posts={group} />
          <section className='section'>
            <div className='buttons is-centered' />
          </section>
        </section>
      </Page>
    )
  }
}
